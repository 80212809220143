<template>
  <div class="forbidden-wrapper">
    Forbidden access!
  </div>
</template>

<script>

export default {
};
</script>

<style lang="scss" scoped>

.forbidden-wrapper {
  padding: 16px;
}
</style>
